import React, {useState, useEffect, useContext} from 'react'
import { UserContext } from "../../../App";
import { useLocation } from 'react-router-dom';

//Components
import DeviceTable from "./DeviceTable";
import DeviceWidget from "./DeviceWidget";
import DeviceCreate from "./DeviceCreate";
import DeviceAuditing from "./DeviceAuditing";
import DevicePopup from './DevicePopup';
import PopupPage from '../PopupPage';
import DeviceEditPopup from './DeviceEditPopup';

//CSS
import "./../Widget.css";
import "./InventoryTable.css";

//Icons
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SearchIcon from '@mui/icons-material/Search';
import BackspaceIcon from '@mui/icons-material/Backspace';
import RefreshIcon from '@mui/icons-material/Refresh';

const Devices = (props) => {
    const { user, FetchCatch, theme } = useContext(UserContext);
    const [select, setSelect] = useState([]);
    const [parent, setParent] = useState([]);
    const [deviceList, setDeviceList] = useState([]);
    const [filter, setFilter] = useState("");
    const [defaultDeviceList, setDefaultDeviceList] = useState([]);
    const { state } = useLocation();

    //Modes
    const [createMode, setCreateMode] = useState(false);
    const [runMode, setRunMode] = useState(false);

    //DevicePopup Page
    const [popupagepagecheck, setPopuppagecheck] = useState(null);
    const [popuppageeditcheck, setPopuppageeditcheck] = useState(null);

    const [osFilter, setOSFilter] = useState(null);
    const [complianceFilter, setComplianceFilter] = useState(null);

    const [width, setWidth]   = useState(window.innerWidth);

    useEffect(() => {
        props.refreshDevices();
    }, [])

    useEffect(() => {
        if(state !== null && deviceList.length === 0 && defaultDeviceList.length === 0){
            let { params } = state;
            if(params == null){
                setDeviceList([...props.deviceList]);
                return;
            }
            let tmp = [];
            if("OS" in params){
                if(params.OS !== null){
                    setOSFilter(params.OS);
                }
            }
            if("Compliance" in params){
                if(params.Compliance !== null){
                    setComplianceFilter(params.Compliance);
                    tmp = [];
                    for(let i in props.deviceList){
                        if(getCompliance(props.deviceList[i].device_id,params.Compliance)) tmp.push(props.deviceList[i]);
                    }
                }
                
                setDefaultDeviceList(tmp);
                setDeviceList(tmp);
                return;
            }

        }

        let tmp = [...props.deviceList];
        if(osFilter != null){
            tmp = tmp.filter(x => x.os_id == osFilter);
        }

        let new_tmp = [];
        if(complianceFilter != null){
            for(let i in tmp){
                if(getCompliance(tmp[i].device_id, complianceFilter)) new_tmp.push(props.deviceList[i]);
            }
        }else{
            new_tmp = tmp;
        }

        setDefaultDeviceList(new_tmp);
        setDeviceList(new_tmp);
        return;
    },[props.deviceList])

    const getCompliance = (device_id, severity) => {
        let index = props.auditList.findIndex(x => x.device_id === device_id && x.status === "Complete");
        if(severity === "Audited"){
            return (index !== -1);
        }
        if(index === -1) return false;
        let audit = props.auditList[index];
        if(severity === "Yes"){
            if(audit.metadata.Open.cat_1 === 0 && audit.metadata.Open.cat_2 === 0) return true;
        }
        if(severity === "No"){
            if(audit.metadata.Open.cat_1 > 0 && audit.metadata.Open.cat_2 > 0) return true;
        }
        return audit.metadata.Open[severity] > 0;
    }
    
    const updateDimensions = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);


    const selectHandler = (host, parentHost, ctrlKey, shiftKey) => {
        let index = select.findIndex(x => x.device_id == host.device_id);
        if(ctrlKey){
            let tmp = [...select];
            if(index == -1){
                tmp.push(host);
            }else{
                tmp.splice(index, 1);
            }
            setSelect(tmp);
        }else if(shiftKey){
            if(select.length == 0 ){
                setSelect([host]);
            }else{
                let firstIndex = deviceList.findIndex(x => x.device_id == select[select.length - 1].device_id);
                let secondIndex = deviceList.findIndex(x => x.device_id == host.device_id);
                let tmp = [];
                if(firstIndex < secondIndex){
                    tmp = deviceList.slice(firstIndex + 1, secondIndex + 1);
                    tmp.push(deviceList[firstIndex]);
                }else{
                    tmp = deviceList.slice(secondIndex, firstIndex + 1);
                }
                setSelect(tmp);
            }
        }else{
            if(index == -1){
                setSelect([host]);
            }else{
                if(select.length > 1){
                    setSelect([host]);
                }else{
                    setSelect([]);
                }
            }
        }
    }

    const addDeviceHandler = async (name, os, group, defaultVariable) => {
        group = props.hostList[props.hostList.findIndex(x => x.group_id == group)];
        await props.newHost(name, group, 1, parseInt(os), defaultVariable);
    }
    
    const auditRunHandler = (status,playbookList,type, group, template_id) => {
        props.runAudit(status,{host: select[0], group: group, playbooks: playbookList, template: template_id},type);
        if(status === 0 || status === 1){
            setRunMode(false);
        }
    }

    const deleteCheckHandler = () => {
        if(select.length == 0) return;
        if(window.confirm("Are you sure you want to delete the selected device(s)?")){
            props.deleteHost(select);
            setSelect([]);
        }
    }

    const saveDevice = async (device) => {
        let copy_select = JSON.parse(JSON.stringify(select[0]));
        if(select[0].name !== device.name){
            let device_data = await API_update_device(device.device_id, device.os_id, device.name);
            if(device_data){
                copy_select.name = device.name;
            }
        }

        for(let i in select[0].device_vars){
            let index = device.device_vars.findIndex(x => x.var_id == select[0].device_vars[i].var_id);
            // var is deleted
            if(index === -1){
                let delete_device_var = await API_delete_device_var(select[0].device_vars[i].var_id);
                if(delete_device_var){
                    copy_select.device_vars.splice(copy_select.device_vars.findIndex(x => x.var_id == select[0].device_vars[i].var_id), 1);
                }
            }else{
                // Check if var is modified
                if(select[0].device_vars[i].var_name !== device.device_vars[index].var_name || select[0].device_vars[i].var_value !== device.device_vars[index].var_value ){
                    let device_var_update = await API_update_device_var(device.device_vars[index].var_id, device.device_id, device.device_vars[index].var_name, device.device_vars[index].var_value);
                    if(device_var_update){
                        copy_select.device_vars[copy_select.device_vars.findIndex(x => x.var_id == select[0].device_vars[i].var_id)] = JSON.parse(JSON.stringify(device.device_vars[index]));
                    }
                }
                device.device_vars.splice(index, 1);
            }
        }

        for(let i in device.device_vars){
            let device_var_create = await API_create_device_var(device.device_id, device.device_vars[i].var_name, device.device_vars[i].var_value);
            if(device_var_create){
                device.device_vars[i].var_id = device_var_create.device_var_id;
                copy_select.device_vars.push(JSON.parse(JSON.stringify(device.device_vars[i])));
            }
        }

        //Updated on frontend 
        props.updateDevice(null, copy_select);
        setSelect([]);
    }

    const API_update_device = async (device_id, os_id, name) => {
        const url_update_device = "/api/devices?id=" + device_id +  "&os_id=" + os_id + "&device_name=" + name;
        let data = await fetch(url_update_device, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${user.token}` },
        })
        .then(res=>{
            if(res.status === 200) {
                window.alert('Device updated successfully.')
            }
            if(res.ok) return res.json();
            throw res;
        })
        .then(data =>{
            return data;
        }).catch(async error => {
            return await FetchCatch(error, API_update_device, [device_id, os_id, name]);
        });

        return data;
    }

    const API_update_device_var = async (var_id, device_id, var_name, var_value) => {
        const url_update_device_var = "/api/devices/vars?id=" + var_id + "&device_id=" + device_id + "&var_name=" + var_name + "&var_val=" + var_value;
        let data = await fetch(url_update_device_var,{
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${user.token}` },
        })
        .then(res=>{
            if(res.ok) return res.json();
            throw res;
        })
        .then(data =>{
            return data;
        }).catch(async error => {
            return await FetchCatch(error, API_update_device_var, [var_id, device_id, var_name, var_value]);
        });

        return data;
    }

    const API_create_device_var = async (device_id, var_name, var_value) => {
        const url_new_device_var = "/api/devices/vars";
        let data = await fetch(url_new_device_var, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${user.token}` },
            body: JSON.stringify({ "device_id": device_id, "var_name": var_name,"var_value": var_value })
        })
        .then(res=>{
            if(res.ok) return res.json();
            throw res;
        })
        .then(data =>{
            return data;

        }).catch(async error => {
            return await FetchCatch(error, API_create_device_var, [device_id, var_name, var_value]);
        });

        return data;
    }

    const API_delete_device_var = async (var_id) => {
        const url_delete_device_var = "/api/devices/vars/" + var_id;
        let data = await fetch(url_delete_device_var, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
        .then(res=>{
            if(res.ok) return;
            throw res;
        }).then(data => {
            return true;
        }).catch(async error => {
            return await FetchCatch(error, API_delete_device_var, [var_id]);
        });

        return data;
    }

    const nameFilter = (type) => {
        let tmp = [...deviceList];
        if(type)
            tmp.sort((a,b) => a.name > b.name ? 1 : -1);
        else
            tmp.sort((a,b) => a.name > b.name ? -1 : 1);
        setDeviceList(tmp);
    }

    const handleFilterChange = (e) => {
        setSelect([]);
        setFilter(e.target.value);
        if(defaultDeviceList.length == 0 && e.target.value != ""){
            setDefaultDeviceList([...deviceList]);
        }
        if(defaultDeviceList.length != 0 && e.target.value == ""){
            setDeviceList(defaultDeviceList);
            setDefaultDeviceList([]);
            return;
        }

        let tmp = [];
        if(defaultDeviceList.length == 0){
            for(let i in deviceList){
                if(deviceList[i].name.includes(e.target.value)){
                    tmp.push(deviceList[i]);
                }
            }
        }else{
            for(let i in defaultDeviceList){
                if(defaultDeviceList[i].name.includes(e.target.value)){
                    tmp.push(defaultDeviceList[i]);
                }
            }
        }
        setDeviceList(tmp);
    }

    const clearFilterHandler = (e) => {
        if(defaultDeviceList.length > 0){
            setDeviceList(defaultDeviceList);
            setDefaultDeviceList([]);
        }else{
            setDeviceList([...props.deviceList]);
        }
        setFilter("");
    }

    const showCreateWidget = () => {
        setSelect([]);
        setCreateMode(true);
    }

    const refreshHandler = async () => {
        if(state != null && state.params != null){
            state.params.OS = null;
            state.params.Compliance = null;
        }
        setComplianceFilter(null);
        setOSFilter(null);
        props.refreshDevices();
    }

    return (
        <div className="inventory-content">
            <div className="inventory-content-container" style={{alignItems: "stretch", color: theme ? "#FFF" : "#000"}}>
                <div className="inventory-content-header">
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <h1>Devices</h1>
                        <div title="Refresh Devices"  style={{display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: "pointer", marginLeft: "10px"}} onClick={refreshHandler}>
                            <RefreshIcon style={{height: "20px", width: "20px", paddingTop: "5px"}}/>
                        </div>
                    </div>
                    <div style={{display: 'flex', borderBottom: "1px solid #454545", width: '100%', paddingBottom: "5px"}}>
                        <div style={{width: "100%", display: 'flex', flexDirection: 'row', alignItems: "center", fontSize: "16px"}}>
                            <div className="inventory-content-header-button"  onClick={() => setPopuppagecheck('Device Creation')}>
                                <AddIcon style={{fontSize: "20px", marginRight: "2px"}} />
                                {/* Create Device */}
                                Create
                            </div>
                            <div className="inventory-content-header-button"  onClick={deleteCheckHandler}>
                                <DeleteForeverIcon style={{fontSize: "20px", marginRight: "2px"}} />
                                {/* Delete Device */}
                                Delete
                            </div>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <SearchIcon style={{fontSize: "20px", marginRight: "2px"}} />
                            <label style={{whiteSpace: "nowrap", marginRight: "5px"}}>Search:</label>
                            <input type="text" value={filter} onChange={handleFilterChange} placeholder='filter by name'/>
                            <div className="inventory-content-header-button" style={{marginLeft: "5px", marginRight: "0px"}} onClick={clearFilterHandler}>
                                <BackspaceIcon style={{fontSize: "18px", marginRight: "2px"}} />
                                Clear
                            </div>
                        </div>
                    </div>
                </div>
                {
                    width <= 1280 &&
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <DeviceCreate display={createMode} groupRole={props.groupRole} selected={null} cancel={() => setCreateMode(false)} hostList={props.hostList} deviceList={props.deviceList} OS={props.OS} addDevice={addDeviceHandler} />
                    <DeviceAuditing groupRole={props.groupRole} selected={select.length > 0 ? select[0] : null} parent={parent} hostList={props.hostList} display={runMode} cancel={() => setRunMode(false)} playbookList={props.playbookList} OS={props.OS} auditRun={auditRunHandler}/>
                    <DeviceWidget groupRole={props.groupRole} selected={select.length > 0 ? select[0] : null} parent={parent} deviceList={props.deviceList} hostList={props.hostList} OS={props.OS} addHost={setCreateMode} runHost={() => setRunMode(true)} viewHost={(type) => props.hostAudit(select.length > 0 ? select[0] : null, type)} unique_group_names={props.uniqueGroupNames} saveDevice={saveDevice} close={() => setSelect([])} edit={() => setPopuppageeditcheck('Edit')} schedule={() => setPopuppageeditcheck('Schedule Task')}/>
                    </div>
                }
                <div className="inventory-content-wrapper">
                    <DeviceTable allRoles={props.allRoles} groupRole={props.groupRole} hostList={props.hostList} deviceList={props.deviceList} devices={deviceList} OS={props.OS} auditList={props.auditList} select={selectHandler} selected={select} filter={filter} nameFilter={nameFilter} />
                </div>
            </div>
            {
                width > 1280 &&
                <div className="inventory-content-container" style={{ marginRight: "20px"}}>
                    <DeviceCreate display={createMode} groupRole={props.groupRole} selected={select.length > 0 ? select[0] : null} cancel={() => setCreateMode(false)} hostList={props.hostList} deviceList={props.deviceList} OS={props.OS} addDevice={addDeviceHandler} />
                    <DeviceAuditing groupRole={props.groupRole} selected={select.length > 0 ? select[0] : null} parent={parent} hostList={props.hostList} display={runMode} cancel={() => setRunMode(false)} playbookList={props.playbookList} OS={props.OS} auditRun={auditRunHandler} />
                    <DeviceWidget groupRole={props.groupRole} selected={select.length > 0 ? select[0] : null} parent={parent} deviceList={props.deviceList} hostList={props.hostList} OS={props.OS} addHost={setCreateMode} runHost={() => setRunMode(true)} viewHost={(type) => props.hostAudit(select.length > 0 ? select[0] : null, type)} unique_group_names={props.uniqueGroupNames} saveDevice={saveDevice} close={() => setSelect([])} edit={() => setPopuppageeditcheck('Edit')} schedule={() => setPopuppageeditcheck('Schedule Task')}/>
                </div>
            }
            {
                popupagepagecheck != null &&
                <PopupPage close={() => setPopuppagecheck(null)} title="Device(s)" content={<DevicePopup page={popupagepagecheck} close={() => setPopuppagecheck(null)} OSList={props.OS} groupList={props.hostList} deviceList={props.deviceList} createDevice={addDeviceHandler} />}/>
            }
            {
                popuppageeditcheck != null &&
                <PopupPage close={() => setPopuppageeditcheck(null)} title={select != null & select.length > 0 ? select[0].name : ""} content={<DeviceEditPopup page={popuppageeditcheck} close={() => setPopuppageeditcheck(null)} OSList={props.OS} groupList={props.hostList} deviceList={props.deviceList} playbookList={props.playbookList} select={select} saveDevice={saveDevice} />}/>
            }
        </div>
    )
}

export default Devices;